<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="clearForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          添加联系人
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="addContact()"
          @reset.prevent="clearForm"
        >
          <!-- 姓 -->
          <validation-provider
            #default="validationContext"
            name="姓"
            rules="required"
          >
            <b-form-group
              label="姓"
              label-for="last-name"
            >
              <b-form-input
                id="last-name"
                v-model="contactData.lastName"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- 名 -->
          <validation-provider
            #default="validationContext"
            name="名"
            rules=""
          >
            <b-form-group
              label="名"
              label-for="first-name"
            >
              <b-form-input
                id="first-name"
                v-model="contactData.firstName"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- 性别 -->
          <validation-provider
            #default="validationContext"
            name="性别"
            rules=""
          >
          
            <b-form-group
              label="性别"
              label-for="gender"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="contactData.gender"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="genderOptions"
                :reduce="val => val.value"
                :clearable="true"
                input-id="user-role"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- 称谓 -->
          <validation-provider
            #default="validationContext"
            name="称谓"
            rules=""
          >
            <b-form-group
              label="称谓"
              label-for="appellation"
            >
              <b-form-input
                id="appellation"
                v-model="contactData.appellation"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- 头衔 -->
          <validation-provider
            #default="validationContext"
            name="职位"
            rules=""
          >
            <b-form-group
              label="职位"
              label-for="title"
            >
              <b-form-input
                id="title"
                v-model="contactData.title"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- 公司 -->
          <validation-provider
            #default="validationContext"
            name="公司"
            rules=""
          >
            <b-form-group
              label="公司"
              label-for="company"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="contactData.company"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="companyOptions"
                :reduce="val => val.value"
                :clearable="true"
                input-id="user-role"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="email"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="contactData.email"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- 电话 -->
          <validation-provider
            #default="validationContext"
            name="电话"
            rules="integer"
          >
            <b-form-group
              label="电话"
              label-for="phone"
            >
              <b-form-input
                id="phone"
                v-model="contactData.phone"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="收件地址"
          >
            <b-form-group
              label="收件地址"
              label-for="receive_address"
            >
              <b-form-input
                id="receive_address"
                v-model="contactData.receiveAddress"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>




          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >保存
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              取消
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    companyOptions: {
      type: Array,
      required: true,
    },
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },

  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      contacts: [],
      contactData: {
        firstName: '',
        lastName: '',
        appellation: '',
        title: '',
        company: '',
        phone: '',
        email: '',
        receiveAddress:'',
      },
      genderOptions:[
        {label:'男',value:'male'},
        {label:'女',value:"female"},
      ],
    }
  },

  methods:{
    clearForm:function(){
      this.$refs.refFormObserver.reset()
      this.contactData = {
        firstName: '',
        lastName: '',
        appellation: '',
        title: '',
        company: '',
        phone: '',
        email: '',
        receiveAddress: '',
      }
    },
    addContact: function(){
      this.$refs.refFormObserver.validate().then((result) => {
        if (result) {
          this.$axios.post("crm/contacts/system/add_contact",this.contactData).then((res)=>{
           if (res.data.status == "ok"){
            this.resetForm()
            this.contactData = {}
            this.$emit("get-contacts")
            this.$emit('update:is-add-new-user-sidebar-active', false)
           }
          })
        }
      })
    },
  },
  setup(props, { emit }) {
    const blankUserData = {
      fullName: '',
      username: '',
      email: '',
      role: null,
      currentPlan: null,
      company: '',
      country: '',
      contact: '',
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const onSubmit = () => {
      store.dispatch('app-user/addUser', userData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
