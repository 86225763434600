<template>

  <div>

    <contact-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :company-options = "companyOptions"
      @get-contacts="getContacts"
    />

    <contact-edit
      :is-edit-user-sidebar-active.sync="isEditUserSidebarActive"
      :contact-data="contactData"
      :company-options = "companyOptions"
      @get-contacts="getContacts"
    />

    <!-- Filters -->
    <users-list-filters
      :user-filter.sync="userFilter"
      :user-options="userOptions"
      :company-filter.sync="companyFilter"
      :company-options="companyOptions"
      :starred-filter.sync="starredFilter"
      :starred-options="starredOptions"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>展示</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>条数据</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="搜索"
                v-b-tooltip.hover.bottom="`联系人姓名 职位 邮箱 电话`"
              />
              <b-button
                variant="primary"
                @click="isAddNewUserSidebarActive = true;;"
              >
                <span class="text-nowrap">添加联系人</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative text-nowrap"
        :items="filterContacts"
        :per-page="perPage"
        :current-page="currentPage"
        responsive
        :fields="fields"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: User -->
        <template #cell(fullName)="data">
          <b-media vertical-align="center">
            <template #aside>
              <div class="text-nowrap">
                <p>
                  <b-link
                    :to="{ name: 'crm-contacts-view', params: { id: data.item.contact_id }}"
                    :class="mapStarredStatus(data.item.starred)"
                    >{{ data.item.last_name }}{{ data.item.first_name }}
                  </b-link>
                </p>
            </div>
            </template>
          </b-media>
        </template>

        <!-- Column: Role -->
        <template #cell(gender)="data">
          <div class="text-nowrap">
            <p>{{ data.item.gender_cn }}</p>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(company)="data">
          <div class="text-nowrap">
            <p>{{ data.item.company_name }}</p>
          </div>
        </template>

        <template #cell(title)="data">
          <div class="text-nowrap">
            <p>{{ data.item.title }}</p>
          </div>
        </template>

        <template #cell(email)="data">
          <div class="text-nowrap">
            <p>{{ data.item.email }}</p>
          </div>
        </template>

        <template #cell(phone)="data">
          <div class="text-nowrap">
            <p>{{ data.item.phone }}</p>
          </div>
        </template>

        <template #cell(belongName)="data">
          <div class="text-nowrap">
            <p>{{ data.item.belong_name }}</p>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'crm-contacts-view', params: { id: data.item.contact_id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">查看详情</span>
            </b-dropdown-item>

            <b-dropdown-item @click="isEditUserSidebarActive = true; selectedContact = data.item.contact_id">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>

            <b-dropdown-item
            @click = "deleteContact(data.item.contact_id)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">删除</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">正在展示第 {{ dataMeta.from }} 到第 {{ dataMeta.to }} 条数据 （共 {{ totalContacts }} 条数据）</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalContacts"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,VBTooltip
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import UsersListFilters from './UsersListFilters.vue'
import ContactAddNew from './ContactAddNew.vue'
import ContactEdit from './ContactEdit.vue'

export default {
  components: {
    UsersListFilters,
    ContactAddNew,
    ContactEdit,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    VBTooltip,

    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data(){
    return {
      starredOptions: [
        { value: true, label: "是" },
      ],
      perPageOptions : [10, 25, 50, 100],
      sortBy :'id',
      isSortDirDesc : true,
      isAddNewUserSidebarActive: false,
      isEditUserSidebarActive: false,
      selectedContact: "",
      sidebarAction: "",
      perPage:10,
      currentPage:1,
      contacts: [],
      searchQuery: "",
      userOptions: [],
      companyOptions: [],
      userFilter: "",
      companyFilter:"",
      starredFilter:null,
      showContcts: [],
      fields:[
        {key:"fullName", label:"姓名"},
        {key:"gender", label:"性别"},
        {key:"company", label:"公司"},
        {key:"title", label:"职位"},
        {key:"email", label:"邮箱"},
        {key:"phone", label:"电话"},
        {key:"belongName",label:"归属人"},
        {key:"actions", label:"操作"},
      ],
    }
  },
  watch:{
    userFilter(val){
    }
  },
  computed:{
    dataMeta: function(){
      return {
        from: (this.currentPage - 1) * this.perPage + 1,
        to: Math.min(this.currentPage * this.perPage, this.totalContacts)
      }
    },
    contactData: function(){
      return this.contacts.filter(item => item.contact_id == this.selectedContact)[0]
    },
    totalContacts: function(){
      return this.filterContacts.length
    },
    filterContacts: {
      get: function(){
        return this.contacts.filter((contact)=>{
            return (
              (contact.last_name.includes(this.searchQuery)
                || contact.first_name.includes(this.searchQuery)
                || contact.email.includes(this.searchQuery)
                || contact.phone.includes(this.searchQuery))
                || contact.title.includes(this.searchQuery)
              )
              && (this.userFilter ? contact.belong_to.includes(this.userFilter) : true)
              && (this.companyFilter ? contact.company.includes(this.companyFilter) : true)
              && (this.starredFilter ? contact.starred == (this.starredFilter) : true)
        })
      },
    },
  },
  methods:{
    mapStarredStatus(s){
      if(s) return "font-weight-bold text-warning"
      else return "font-weight-bold"
    },
    getUsers(){
      this.$axios.get("/manage/users/get_users").then((res)=>{
        if (res.data.status == "ok"){
          res.data.data.users.forEach((user)=>{
            this.userOptions.push({label:user.user_name,value:user.user_id})
          })
        }
      })
    },
    getContacts: function(){
      this.$axios.get("crm/contacts/system/get_contacts").then((res)=>{
        if (res.data.status == "ok"){
          this.contacts = res.data.data.contacts;
        }
      })
    },
    getCompanies: function(){
      this.$axios.get("crm/companies/system/get_companies").then((res)=>{
        if (res.data.status == "ok"){
          res.data.data.companies.forEach((company)=>{
            this.companyOptions.push({label:company.name,value:company.company_id})
          })
        }
      })
    },
    deleteContact: function(contact_id){
      this.$swal({
        title: '删除联系人',
        text: "删除后无法撤销，你确定要删除该联系人吗？",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '是的，删除！',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$axios.get("crm/contacts/system/del_contact/" + contact_id).then((res)=>{
        if (res.data.status == "ok"){
          this.getContacts();
        }
      })        
    }
      })


    }
  },
  created(){
    this.getContacts()
    this.getUsers()
    this.getCompanies()
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
